*,
*:before,
*:after {
    box-sizing: inherit;
}


html {
    box-sizing: border-box;
    line-height: $base-line-height;
    scroll-behavior: smooth;
    font-size: $base-font-size;
}

body {
    margin: 0;
    font-family: $base-font-family;
    background-color: $primary-color;
    color: $base-color;
    font-weight: $base-font-weight;
    @media (min-width: $lg) {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }
}

img{
    max-width: 100%;
    height: auto;
    vertical-align: bottom;
}

figure {
    margin: 0;
}

a{
    display: inline-block;
    color: $primary-color;
    text-decoration: none;
    box-shadow: inset 0 0 0 $primary-color;
    transition: color 200ms, box-shadow 200ms;
    &:hover{
        color: $base-color;
        box-shadow: inset 0 -2px 0 $primary-color;
    }
    &:active{
        color: darken($primary-color,20%);
    }
}

h1{
    color: $secondary-color;
    margin-top: 2em;
    font-size: 1.6em;
}

h2 {
    color: $secondary-color;
    margin-top: 2em;
    font-size: 1.5em;
}

h3 {
    font-size: 1.2em;
}

.title {
    display: block;
    margin-top: 2em;
    + h2 {
        margin-top: 0;
    }
}

.underconstruction {
    color: $uc-color;
    font-size: 1.3em;
    margin-bottom: 4em;
}