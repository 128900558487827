.container{
    max-width: 1400px;
    margin: 0 auto;
    padding-left: 5%;
    padding-right: 5%;
}

.flex{
    @media screen and (min-width: $sm) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

header{
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    @media (min-width: $lg) {
        padding-top: 3rem;
    }
}

.brand{
    max-width: 300px;
    margin-bottom: 1.5rem;
    @media screen and (min-width: $sm) {
        margin-bottom: 0;
    }
}

.info{
    > span {
        display: block;
    }
    @media screen and (min-width: $sm) {
        text-align: right;
    }
}

main {
    background-color: $base-bg-color;
    box-shadow: 0 0 13px 7px rgba(0,0,0,.14);
    border: 10px solid $primary-color;
}

footer {
    margin-top: 5em;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-top: 2px solid $primary-color;
    @media (min-width: $lg) {
        padding-bottom: 3rem;
    }
}

.mambership{
    margin-top: 2.5rem;
    @media screen and (min-width: $sm) {
        width: 35%;
        margin: 0;
    }
}