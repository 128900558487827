@font-face {
    font-family: 'Montserrat';
    src: url('#{$font-path}Montserrat-Regular.woff2') format('woff2'),
    url('#{$font-path}Montserrat-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('#{$font-path}Montserrat-Bold.woff2') format('woff2'),
    url('#{$font-path}Montserrat-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('#{$font-path}Montserrat-Italic.woff2') format('woff2'),
    url('#{$font-path}Montserrat-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}