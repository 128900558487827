/*!
Template: TQS Management
Author: Christian Hoerzer
Author Uri: https://christian-hoerzer.at/
Theme Uri: https://christian-hoerzer.at/
Version: 1.0
*/

@import "variables";
@import "fonts";
@import "base";
@import "layout";