
$base-font-family: 'Montserrat', sans-serif;
$base-font-size: 18px;
$base-font-weight: 400;
$base-line-height: 1.4;
$base-bg-color: #fff;
$base-color: #756d6b;

$primary-color: #5a9fc9;
$secondary-color: #41316F;
$uc-color: #d54e4e;

$font-path: '../fonts/';

$sm: 700px;
$lg: 1450px;
